import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useYaMetrica } from '~frontend/provider/yametrica';
import { useCopyToClipboard, useUpdateEffect, useMount } from 'react-use';
import { Helmet } from 'react-helmet';

import { toast } from 'react-toastify';

import '~public/frontend/scss/pages/_404.scss';

const NotFound: FunctionComponent = () => {
  const [state, copyToClipboard] = useCopyToClipboard();
  const { reachGoal } = useYaMetrica();

  useMount(() => {
    reachGoal('404');
  });

  useUpdateEffect(() => {
    toast('Скопировано');
  }, [state]);

  const { t } = useTranslation();

  const onCopyToClipboard = () => {
    copyToClipboard('404PNF');
  };

  return (
    <>
      <Helmet>
        <title>NotFound</title>
      </Helmet>
      <div className="page-404-wrap">
        <div className="page-404">
          <div
            className="page-404-text"
            dangerouslySetInnerHTML={{ __html: t('This is our secret page') }}
          />
          <div className="page-404-code">
            <div className="refer-input">
              <input type="text" value="404PNF" readOnly />
              <a href="#" onClick={onCopyToClipboard} className="link-copy">
                <span className="icon icon-copy" />
              </a>
            </div>
            <div
              className="page-404-input-label"
              dangerouslySetInnerHTML={{
                __html: t(
                  'Enter this code while topping up the balance and get 5 to the payment amount',
                ),
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default NotFound;
